import axios from "axios";

class FreshWorkAPI {
  constructor() {
    this.api = axios.create({
      baseURL: process.browser ? process.env.BASE_URL + "/crm" : "",
      withCredentials: true
    });
  }

  async createLeadId(name, phone, token, cpf, telefone_valido) {
    return await this.api.post("/contacts/store", {
      first_name: name,
      mobile_number: phone,
      cf_cpf: cpf,
      cf_telefone_validado: telefone_valido
    }, {
      timeout: 10000
    });
  }

  async verificarTelefone() {
    return await this.api.post(
      `/contacts/verificar-telefone`
    );
  }

  async updateLeadCPF(cpf) {
    return await this.api.post(
      `/contacts/${localStorage.getItem("leadId")}/update`,
      {
        custom_field: {
          cf_cpf: cpf
        }
      }
    );
  }

  async getDeal(value) {
    return await this.api.post(`/deal/code/${value}`);
  }

  async getData() {
    return await axios.post(`${process.env.BASE_URL}/getdata`, {send: 'send'}, {
      withCredentials: true
    });
  }

}

export default FreshWorkAPI;
