
import FreshWorkAPI from "../utils/freskwork-api";
import BankingPartnersList from "../components/BankingPartnersList.vue";
import ContentWrapper from "../components/ContentWrapper.vue";

export default {
  data: () => ({
    step: 1,
    code: "",
    contratoIsEnable: false,
    apiFresh: new FreshWorkAPI(),
    gtagEventSent: false,
    dataContrato: [],
    isLoading: false,
    leadId: null,
    c6: false,
    facta: false,
    safra: false,
    bancobv: false,
    icred: false,
    bancoPan: false,
  }),
  methods: {
    nextStep() {
      this.step++;
      if (this.step === 4 && !this.gtagEventSent) {
        // this.$gtag.event('conversion', { 'send_to': 'AW-409498198/ayCBCNO6uPsBENbkocMB' });
        this.gtagEventSent = true;
      }
    },
    async openWhatsapp() {
      const url =
        "https://api.whatsapp.com/send?phone=5551981689988&text=Quero%20contratar%20antecipa%C3%A7%C3%A3o%20do%20FGTS%20para%20o%20meu%20CPF%3A%20" +
        this.dataContrato.CPF;
      window.open(url, "_blank");
    },
    async openProposta() {
      const url = this.dataContrato.UrlProposta;
      window.open(url, "_blank");
    },
    previousStep() {
      this.step--;
    },
    async createLeadId() {
      const result = await this.api.createLeadId();
      if (process.browser) {
        localStorage.setItem("leadId", result.data.leadId);
      }
    },
  },
  async mounted() {
    if (this.$route.query.c !== undefined) {
      await this.apiFresh.getDeal(this.$route.query.c).then((r) => {
        if (r.data.Aprovado == "S") {
          this.contratoIsEnable = true;
          this.dataContrato = r.data;
          switch (r.data.CodigoBanco) {
            case "149":
              this.facta = true;
              break;
            case "422":
              this.safra = true;
              break;
            case "336":
              this.c6 = true;
              break;
            case "623":
              this.bancoPan = true;
              break;
            case "413":
              this.bancobv = true;
              break;
            case "435":
              this.icred = true;
              break;
          }
        }
      });
    }
    if (this.$route.query.ref !== undefined) {
      this.$cookies.set("ref", this.$route.query.ref, {
        path: "/",
        domain: ".minucred.com",
      });
      this.$cookies.set("origem", "indique.minucred.com", {
        path: "/",
        domain: ".minucred.com",
      });
    }
  },
  async asyncData({ context, app }) {},
  components: { ContentWrapper, BankingPartnersList },
};
